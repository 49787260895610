import React from "react";

const AdjustmentIconSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.5 13.332H14.5H8.5Z" fill={color} />
      <path
        d="M8.5 13.332H14.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5 2.33218C11.7652 2.06697 12.1249 1.91797 12.5 1.91797C12.6857 1.91797 12.8696 1.95455 13.0412 2.02562C13.2128 2.09669 13.3687 2.20086 13.5 2.33218C13.6313 2.4635 13.7355 2.61941 13.8066 2.79099C13.8776 2.96257 13.9142 3.14647 13.9142 3.33218C13.9142 3.5179 13.8776 3.7018 13.8066 3.87338C13.7355 4.04496 13.6313 4.20086 13.5 4.33218L5.16667 12.6655L2.5 13.3322L3.16667 10.6655L11.5 2.33218Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AdjustmentIconSVG;
