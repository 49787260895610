import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/leave-type`;

const leaveTypeApi = createApi({
  reducerPath: "leaveType",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
    fetchFn: async (...args) => {
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      fetchLeaveType: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          result && tags.push(...result.data.map((leaveType) => ({
            type: "LeaveType",
            id: leaveType._id,
          })));
          tags.push({ type: "LeaveTypeAll" });
          return tags;
        },
        query: (filter = {}) => {
          return {
            url: `/get?${Object.entries(filter || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`,
            method: "GET",
          };
        },
      }),
      addLeaveType: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "LeaveTypeAll" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: {
              name: body.name,
              defaultAmount: body.defaultAmount,
              gainPerMonth: body.gainPerMonth,
              expiredCarryOver: body.expiredCarryOver,
              maxCarryOver: body.maxCarryOver,
              periodOfEmployments: body.periodOfEmployments,
            },
          };
        },
      }),
      updateLeaveType: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "LeaveTypeAll" }];
        },
        query: (body) => {
          return {
            url: `/${body.name}`,
            method: "PUT",
            body: {
              name: body.name,
              defaultAmount: body.defaultAmount,
              gainPerMonth: body.gainPerMonth,
              expiredCarryOver: body.expiredCarryOver,
              maxCarryOver: body.maxCarryOver,
              periodOfEmployments: body.periodOfEmployments,
            },
          };
        },
      }),
      deleteLeaveType: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "LeaveTypeAll" }];
        },
        query: (body) => {
          return {
            url: `/${body.name}`,
            method: "DELETE",
          };
        },
      }),
    };
  },
});

export const {
  useFetchLeaveTypeQuery,
  useAddLeaveTypeMutation,
  useUpdateLeaveTypeMutation,
  useDeleteLeaveTypeMutation,
} = leaveTypeApi;
export { leaveTypeApi };
