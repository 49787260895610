import React from "react";
import PropTypes from "prop-types";

const DownloadSVG = ({ color, className, ...otherProps }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={color} className={`w-5 h-5 mr-auto ${className}`}>
      <path id="Stroke 1" d="M12.1222 15.4361L12.1222 3.39514" stroke="#201140" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Stroke 3" d="M15.0382 12.5084L12.1222 15.4364L9.20621 12.5084" stroke="#201140" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Stroke 4" d="M16.755 8.12805H17.688C19.723 8.12805 21.372 9.77705 21.372 11.8131V16.6971C21.372 18.7271 19.727 20.3721 17.697 20.3721L6.55701 20.3721C4.52201 20.3721 2.87201 18.7221 2.87201 16.6871V11.8021C2.87201 9.77305 4.51801 8.12805 6.54701 8.12805L7.48901 8.12805" stroke="#201140" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

DownloadSVG.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

DownloadSVG.defaultProps = {
  color: "#111111",
  className: "",
};

export default DownloadSVG;
