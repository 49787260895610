import React, { useState } from "react";
import {
  Button,
  InputNumber,
  InputSelect,
  ModalDialog,
  TextArea,
} from "@bluesilodev/timhutcomponents";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from 'yup';
import AdjustmentIconSVG from "assets/icon/AdjustmentIconSVG/AdjusmetIconSVG";
import XMarkSVG from "assets/icon/XMarkSVG/XMarkSVG";
import { useAlertApi } from "services/alert-api";
import { useUpdateLeaveBalanceMutation } from "store/apis/leaveBalanceApi";

export const LeaveBalanceDetailsAdjustmentModalForm = ({
  userID,
  adjustmentNote,
  dataTable,
  isModalOpen,
  setIsModalOpen,
}) => {
  // Formik
  const formik = useFormik({
    initialValues: {
      leaveBalanceData: [],
      notes: "",
    },
    validationSchema: Yup.object().shape({
      leaveBalanceData: Yup.array().of(Yup.object().shape({
        leaveTypeID: Yup.string().required('Leave Type ID is required'),
        leaveTypeName: Yup.string().required('Leave Type Name is required'),
        adjustment: Yup.number().required('Adjustment is required'),
      })),
    }),
    validateOnBlur: true,
    onSubmit: (values) => {
      updateAdjustmentLeaveBalance({
        userID: userID,
        note: values.notes || adjustmentNote,
        payload: values.leaveBalanceData.map((item) => {
          const { leaveTypeName, ...rest } = item;
          return rest;
        }),
      });
    },
  });
  // Element State
  const [isEnter, setEnter] = useState("#DD7224");
  // Redux Toolkit
  const [updateAdjustmentLeaveBalance, updateAdjustmentLeaveBalanceResult] = useUpdateLeaveBalanceMutation();

  React.useEffect(() => {
    formik.setFieldValue("leaveBalanceData", [
      ...dataTable.map((balanceItem) => ({
        leaveTypeID: balanceItem.leaveTypeID,
        leaveTypeName: balanceItem.leaveTypeName,
        adjustment: balanceItem.adjustment,
      })),
    ]);
  }, [dataTable]);

  const alertSuccessClose = (modal) => {
    handleCloseModal(false);
  };
  useAlertApi(updateAdjustmentLeaveBalanceResult, alertSuccessClose);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      {isModalOpen && (
        <div className="w-full">
          <ModalDialog
            title={"Adjust Leave Balance"}
            onClose={handleCloseModal}
            onSubmit={handleCloseModal}
            className={"w-[1100px] p-6 max-h-[90%]"}
          >
            <FormikProvider value={formik}>
              <div className="flex flex-row gap-5 justify-center">
                <div className="flex flex-col gap-5">
                  <FieldArray
                    name="leaveBalanceData"
                    render={(arrayHelpers) => (
                      <React.Fragment>
                        {formik.values.leaveBalanceData.map((itemType) => {
                          return (
                            <div>
                              <InputSelect
                                classname={"w-[500px] h-[66px]"}
                                title={"Type of Leave"}
                                options={[{ label: itemType.leaveTypeName, value: itemType.leaveTypeName }]}
                                value={itemType.leaveTypeName}
                                disabled={true}
                              />
                            </div>
                          );
                        })}
                      </React.Fragment>
                    )}
                  />
                </div>

                <div className="flex flex-col gap-5">
                  <FieldArray
                    name="leaveBalanceData"
                    render={(arrayHelpers) => (
                      <React.Fragment>
                        {formik.values.leaveBalanceData.map((itemType, itemIndex) => {
                          return (
                            <div className="w-[500px]">
                              <InputNumber
                                label={`${itemType.leaveTypeName} Adjustment`}
                                name={`${itemType.leaveTypeName}Adjustment`}
                                unit={"Days"}
                                min={0}
                                value={itemType.adjustment}
                                onChange={(val) => {
                                  formik.setFieldValue(`leaveBalanceData[${itemIndex}].adjustment`, val || 0);
                                }}
                              />
                            </div>
                          );
                        })}
                      </React.Fragment>
                    )}
                  />
                  {/* <div className="w-[500px]">
                    <InputNumber label={"Annual Leave Adjusment"} name={"AnnualLeaveAdjusment"} unit={"Days"} min={0} value={leaveBalanceDetailsData.annualLeaveAdj} onChange={(val) => { setLeaveBalanceDetailsData((oldV) => ({ ...oldV, annualLeaveAdj: val || 0 })) }} />
                  </div>

                  <div className="w-[500px]">
                    <InputNumber label={"Sick Leave Adjusment"} name={"SickLeaveAdjusment"} unit={"Days"} min={0} value={leaveBalanceDetailsData.sickLeaveAdj} onChange={(val) => { setLeaveBalanceDetailsData((oldV) => ({ ...oldV, sickLeaveAdj: val || 0 })) }} />
                  </div>

                  <div className="w-[500px]">
                    <InputNumber label={"Off in Line"} name={"OffInLineAdjustment"} unit={"Days"} min={0} value={leaveBalanceDetailsData.offInLineAdj} onChange={(val) => { setLeaveBalanceDetailsData((oldV) => ({ ...oldV, offInLineAdj: val || 0 })) }} />
                  </div>

                  <div className="w-[500px]">
                    <InputNumber label={"NS Adjusment"} name={"NSAdjusment"} unit={"Days"} min={0} value={leaveBalanceDetailsData.nsAdj} onChange={(val) => { setLeaveBalanceDetailsData((oldV) => ({ ...oldV, nsAdj: val || 0 })) }} />
                  </div> */}
                </div>
              </div>

              <div className="mt-5">
                <TextArea label={"Notes"} onChange={(e) => { formik.setFieldValue("notes", e.target.value) }} />
                Current Note: {adjustmentNote}
              </div>

              <div className="mt-5 flex flex-row gap-2">
                <Button
                  onMouseEnter={() => {
                    setEnter("white");
                  }}
                  onMouseLeave={() => {
                    setEnter("#DD7224");
                  }}
                  label={
                    <div className="flex px-4 gap-1">
                      <XMarkSVG color={isEnter} />
                      <div>Cancel</div>
                    </div>
                  }
                  className={"w-full"}
                  onClick={handleCloseModal}
                />

                <Button
                  label={
                    <div className="flex px-4 gap-1">
                      <AdjustmentIconSVG color={"white"} />
                      <div>Adjust</div>
                    </div>
                  }
                  style={"solid"}
                  className={"w-full"}
                  onClick={() => formik.submitForm()}
                />
              </div>
            </FormikProvider>
          </ModalDialog>
        </div>
      )}
    </React.Fragment>
  );
};
