import React from "react";
import PropTypes from "prop-types";

const ChevronLeftSVG = ({ color, className, ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke={color}
      className={`w-4 h-2 mr-auto ${className}`}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 19.5 8.25 12l7.5-7.5"
      />
    </svg>
  );
};

ChevronLeftSVG.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

ChevronLeftSVG.defaultProps = {
  color: "#111111",
  className: "",
};

export default ChevronLeftSVG;
