import React from "react";
import PropTypes from "prop-types";

const FileTypeJPGSVG = ({ color, className, ...otherProps }) => {
  return (
    <svg viewBox="0 0 69 87" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={color} className={`w-5 h-5 mr-auto ${className}`}>
      <path d="M11.2987 68.5373C13.2888 66.5503 13.2888 63.3288 11.2987 61.3419C9.30859 59.3549 6.08201 59.3549 4.09191 61.3419C2.10182 63.3288 2.10182 66.5503 4.09191 68.5373C6.08201 70.5242 9.30859 70.5242 11.2987 68.5373Z" fill="#2BB673"/>
      <path d="M7.70196 71.0169C4.34459 71.0169 1.63171 68.2934 1.63171 64.9563C1.63171 61.6192 4.35958 58.8956 7.70196 58.8956C11.0443 58.8956 13.7722 61.6192 13.7722 64.9563C13.7722 68.2934 11.0443 71.0169 7.70196 71.0169ZM7.70196 60.8261C5.42374 60.8261 3.58019 62.6817 3.58019 64.9413C3.58019 67.2159 5.43873 69.0566 7.70196 69.0566C9.98018 69.0566 11.8237 67.201 11.8237 64.9413C11.8237 62.6667 9.98018 60.8261 7.70196 60.8261Z" fill="#1A1A2E"/>
      <path d="M45.1427 1.88074H20.3221C13.5474 1.88074 8.04675 7.35776 8.04675 14.1367V72.9025C8.04675 79.6664 13.5325 85.1584 20.3221 85.1584H55.7394C62.5141 85.1584 68.0148 79.6814 68.0148 72.9025V24.7166L45.1427 1.88074Z" fill="#FDFDFD"/>
      <path d="M55.7394 86.1162H20.3221C13.0228 86.1162 7.07251 80.1902 7.07251 72.8875V14.1367C7.07251 6.84901 13.0079 0.908081 20.3221 0.908081H45.1427C45.3975 0.908081 45.6523 1.01284 45.8322 1.19241L68.6893 24.0133C68.8691 24.1929 68.974 24.4473 68.974 24.7017V72.8875C68.974 80.1902 63.0387 86.1162 55.7394 86.1162ZM20.3221 2.85347C14.102 2.85347 9.02098 7.91149 9.02098 14.1367V72.9025C9.02098 79.1128 14.087 84.1858 20.3221 84.1858H55.7394C61.9595 84.1858 67.0406 79.1277 67.0406 72.9025V25.1057L44.738 2.85347H20.3221Z" fill="#1A1A2E"/>
      <path d="M45.1427 1.88074L67.9998 24.7017H50.0139C47.316 24.7017 45.1427 22.5168 45.1427 19.8382V1.88074Z" fill="#E3E3E3"/>
      <path d="M67.9998 25.6744H50.0139C46.7914 25.6744 44.1685 23.0556 44.1685 19.8382V1.88073C44.1685 1.49165 44.4083 1.1325 44.768 0.982856C45.1277 0.833211 45.5474 0.908037 45.8322 1.19236L68.6443 23.9684C68.8541 24.148 68.974 24.4024 68.974 24.7017C68.974 25.2404 68.5394 25.6744 67.9998 25.6744ZM46.1169 4.23016V19.8382C46.1169 21.9931 47.8706 23.729 50.0139 23.729H65.6466L46.1169 4.23016Z" fill="#1A1A2E"/>
      <path d="M8.16661 35.5659C5.13898 35.5659 2.68091 38.0201 2.68091 41.0429V63.9836H2.78582C3.28043 61.4546 5.4987 59.5541 8.16661 59.5541H63.4283V35.5659H8.16661Z" fill="#2BB673"/>
      <path d="M2.77081 64.9712H2.66589C2.12631 64.9712 1.69165 64.5372 1.69165 63.9985V41.0579C1.69165 37.4963 4.58439 34.6082 8.1516 34.6082H63.4133C63.9529 34.6082 64.3876 35.0421 64.3876 35.5808V59.569C64.3876 60.1077 63.9529 60.5417 63.4133 60.5417H8.16658C6.00827 60.5417 4.14973 62.0681 3.73006 64.1931C3.64013 64.627 3.23544 64.9712 2.77081 64.9712ZM8.16658 36.5386C5.67853 36.5386 3.65512 38.5588 3.65512 41.0429V60.407C4.8242 59.2697 6.42794 58.5664 8.16658 58.5664H62.4541V36.5386H8.16658Z" fill="#1A1A2E"/>
      <path d="M16.65 52.1166C16.5451 52.0119 16.4402 51.8173 16.4402 51.6228C16.4402 51.2337 16.7699 50.9045 17.1596 50.9045C17.3994 50.9045 17.5643 51.0392 17.6692 51.1589C18.3737 52.1166 19.1231 52.6104 20.3221 52.6104C21.776 52.6104 22.8552 51.5629 22.8552 49.5128V41.5217C22.8552 41.1177 23.1849 40.7885 23.5896 40.7885C23.9943 40.7885 24.309 41.1177 24.309 41.5217V49.4978C24.309 51.0092 23.8594 52.1465 23.1249 52.8798C22.4055 53.5831 21.4313 53.9423 20.2772 53.9423C18.5985 53.9573 17.4444 53.1791 16.65 52.1166Z" fill="#FDFDFD"/>
      <path d="M27.7713 41.6116C27.7713 41.2075 28.1011 40.8783 28.4908 40.8783H32.5976C35.5053 40.8783 37.4238 42.4197 37.4238 44.9636V44.9935C37.4238 47.7769 35.1006 49.2136 32.3428 49.2136H29.2102V53.1193C29.2102 53.5233 28.8805 53.8526 28.4758 53.8526C28.0861 53.8526 27.7563 53.5233 27.7563 53.1193V41.6116H27.7713ZM32.4177 47.8967C34.561 47.8967 35.9549 46.7594 35.9549 45.0534V45.0235C35.9549 43.1828 34.576 42.2251 32.4926 42.2251H29.2252V47.8967H32.4177Z" fill="#FDFDFD"/>
      <path d="M39.5221 47.3579V47.328C39.5221 43.7964 42.1301 40.6538 45.997 40.6538C47.9455 40.6538 49.2195 41.1626 50.4036 42.0306C50.5684 42.1652 50.7033 42.3598 50.7033 42.6142C50.7033 43.0182 50.3736 43.3474 49.9839 43.3474C49.819 43.3474 49.6692 43.2726 49.5193 43.1679C48.59 42.4645 47.5708 41.9857 45.9371 41.9857C43.0743 41.9857 41.0359 44.395 41.0359 47.2681V47.2981C41.0359 50.3808 43.0144 52.6554 46.1469 52.6554C47.6158 52.6554 48.9947 52.0867 49.879 51.3684V48.166H46.4167C46.072 48.166 45.7722 47.8667 45.7722 47.5225C45.7722 47.1484 46.072 46.8641 46.4167 46.8641H50.5684C50.9581 46.8641 51.2879 47.1933 51.2879 47.5974V51.5181C51.2879 51.8622 51.123 52.1765 50.7933 52.401C49.6092 53.2689 48.0204 53.9723 46.1019 53.9723C42.0401 53.9872 39.5221 51.0392 39.5221 47.3579Z" fill="#FDFDFD"/>
    </svg>
  );
};

FileTypeJPGSVG.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FileTypeJPGSVG.defaultProps = {
  color: "#111111",
  className: "",
};

export default FileTypeJPGSVG;
