import React, { useState } from "react";
import {
  Button,
  InputSelect,
  SearchBox,
} from "@bluesilodev/timhutcomponents";
import UserAddIcon from "assets/icon/UserAddSVG/UserAdd";
import FetchingAndError from "components/fetchingAndError";
import LeaveExpiredDataTable from "pages/leaveExpired/tables";
import axios from "services/axios";
import { useFetchLocationDepartmentQuery } from "store/apis/externalApi";
import { useFetchLeaveExpiredQuery } from "store/apis/leaveBalanceApi";
import { useFetchLeaveTypeQuery } from "store/apis/leaveTypeApi";

const dataTableDummy = [];
/*
const dataTableDummy = [
  {
    employee: "Halim",
    location: "Cafe Johandi",
    uId: "298djdj",
    jobPosition: {
      position: "HR",
      department: "Operation",
    },
    annualLeave: "3 Days",
    sickLeave: "2 Days",
    offInLine: "1 Days",
    ns: "3 Days",
  },
  {
    employee: "Haley",
    location: "Cafe Johandi",
    uId: "298djdj",
    jobPosition: {
      position: "HR",
      department: "Operation",
    },
    annualLeave: "3 Days",
    sickLeave: "2 Days",
    offInLine: null,
    ns: null,
  },
  {
    employee: "Hamilton",
    location: "Cafe Halim",
    uId: "298djdj",
    jobPosition: {
      position: "HR",
      department: "Operation",
    },
    annualLeave: "3 Days",
    sickLeave: "2 Days",
    offInLine: null,
    ns: null,
  },
];
*/

const LeaveExpired = () => {
  // Data State
  const [allDataTable, setAllDataTable] = useState(dataTableDummy);
  const [dataTable, setDataTable] = useState(dataTableDummy);
  const [globalFilter, setGlobalFilter] = useState({ locations: [] });
  const [locations, setLocations] = useState([{ label: "", value: "" }]);
  const [departments, setDepartments] = useState([{ label: "", value: "" }]);
  const [paginate, setPaginate] = useState({ totalData: 0, rowsPerPage: 10, currentPage: 1 });
  const [filterData, setFilterData] = useState({ search: "", year: 0, location: "", department: "" });
  // Element State
  const [timerStopTyping, setTimerStopTyping] = useState(null);
  const [isEnter, setEnter] = useState("#DD7224");
  // Redux Toolkit
  const { data: dataLocationDep, error: errorLocationDep, isFetching: isFetchingLocationDep } = useFetchLocationDepartmentQuery(null, { refetchOnMountOrArgChange: true });
  const { data: dataLeaveType, error: errorLeaveType, isFetching: isFetchingLeaveType } = useFetchLeaveTypeQuery(null, { refetchOnMountOrArgChange: true });
  const { data: dataLeaveExpired, error: errorLeaveExpired, isFetching: isFetchingLeaveExpired } = useFetchLeaveExpiredQuery({ ...filterData, limit: paginate.rowsPerPage, page: paginate.currentPage }, { refetchOnMountOrArgChange: true });

  // Static State
  /*
  const timeRanges = [
    "Today",
    "Past Week",
    "This Week",
    "Last Month",
    "This Month",
    "Coustom Date",
  ];
  */

  React.useEffect(() => {
    setDataTable((oldV) => {
      return allDataTable.filter((value) => {
        const searchTrue = filterData.search ? Object.keys(value).some((key) => String(value[key]).includes(filterData.search)) : true;
        const departmentTrue = filterData.department ? value.jobPosition.department === filterData.department : true;
        const locationTrue = filterData.location ? value.location === filterData.location : true;
        return searchTrue && departmentTrue && locationTrue;
      });
    });
    const departmentTempData = globalFilter.locations.filter((item) => item.locationName === filterData.location || filterData.location === "").flatMap((entry) => entry.departments).map((item, index) => {
      return {
        label: item.department,
        value: item.department,
      };
    });
    const departmentFInal = [];
    departmentTempData?.forEach((item) => {
      if (!departmentFInal.some((single) => single.label === item.label)) {
        departmentFInal.push({
          label: item.label,
          value: item.value,
        });
      }
    })
    setDepartments(departmentFInal);
  }, [filterData]);

  React.useEffect(() => {
    if (dataLocationDep) {
      const department = [];
      const location = dataLocationDep.data.map((locationVal) => {
        locationVal.departments.forEach((departmentVal) => {
          if (department.find((value) => value.value === departmentVal.department)) return;
          department.push({
            value: departmentVal.department,
            label: departmentVal.department,
          });
        });
        return {
          value: locationVal.locationName,
          label: locationVal.locationName,
        };
      });
      setDepartments(department);
      setLocations(location);
      setGlobalFilter({ locations: dataLocationDep.data });
    }
  }, [dataLocationDep]);

  React.useEffect(() => {
    const year = new Date().getFullYear();
    setDataTable((oldVal) => {
      let returnData = [];
      if (dataLeaveExpired && dataLeaveType) {
        setPaginate((oldData) => ({ ...oldData, totalData: dataLeaveExpired.totalDocs }));
        const leaveType = dataLeaveType.data.map((val) => ({ leaveTypeId: val.uId, leaveType: val.name, expiredCarryOver: `0${val.expiredCarryOver}`.slice(-2) }));
        returnData = [...dataLeaveExpired.data.map((val) => {
          const singleData = {
            employeeID: val.employeeInformation.employeeID,
            userID: val.userID,
            uId: val.uId,
            employee: val.employeeInformation.userName,
            photo: val.employeeInformation.photo?.[0].link || "",
            jobPosition: {
              position: val.employeeInformation?.userInformation?.employeementDetail?.jobPosition || "",
              department: val.employeeInformation?.userInformation?.employeementDetail?.departments || "",
            },
          };
          leaveType.forEach((leaveTypeItem) => {
            const valBalance = val.balance[year].find((type) => type.leaveTypeID === leaveTypeItem.leaveTypeId);
            singleData[leaveTypeItem.leaveType] = {
              expiredCarryOver: leaveTypeItem.expiredCarryOver,
              fullYearEntitlement: valBalance?.fullYearEntitlement,
              forfeitedBF: valBalance?.fullYearEntitlement,
              unusedBroughtForward: valBalance?.unusedBroughtForward,
            };
          });
          return singleData;
        })];
      } else {
        returnData = [];
      }
      setAllDataTable(returnData);
      return returnData;
    });
  }, [dataLeaveExpired, dataLeaveType]);

  const clickExportLeaveExpired = () => {
    axios
      .get(`/api/leave-balance/get-expired?export=true&${Object.entries({ ...filterData, limit: paginate.rowsPerPage, page: paginate.currentPage } || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`)
      .then((response) => {
        const href = URL.createObjectURL(new Blob([response.data], { type: "text/csv;charset=utf-8," }));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "download.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const onChangeTypeSearch = (e) => {
    clearTimeout(timerStopTyping);
    const newTimer = setTimeout(() => {
      setFilterData((oldV) => ({ ...oldV, search: e.target.value }));
    }, 500);
    setTimerStopTyping(newTimer);
  };

  return (
    <div className="w-full h-max relative mt-5 p-1">
      <div className="w-full flex justify-between">
        <div className="flex flex-row">
          {/*
          <div id="time-range" className="mr-5">
            <InputSelect
              title={"Time Range"}
              options={timeRanges}
              classname={"w-[200px] h-[50px] time-range"}
            />
          </div>
          */}

          <div id="time-range" className="mr-5">
            <div className="w-full">
              <div className="w-[320px]">
                {/*
                <InputDateRange label={"Year"} value={"26/10/2023"} onChange={() => {}} error={""} />
                */}
                <InputSelect
                  title={"Year"}
                  options={Array(50).fill().map((_, idx) => 1980 + idx).map((val) => ({ label: val, value: val }))}
                  classname={"w-[320px] h-[55px]"}
                  onChange={(e) => setFilterData((oldV) => ({ ...oldV, year: e.target.value }))}
                />
              </div>
            </div>
          </div>

          <div id="location" className="mr-5">
            <InputSelect
              title={"Location"}
              options={locations}
              classname={"w-[200px] h-[50px]"}
              onChange={(e) => setFilterData((oldV) => ({ ...oldV, location: e.target.value }))}
            />
          </div>

          <div id="department" className="mr-5">
            <InputSelect
              title={"Departement"}
              options={departments}
              classname={"w-[200px] h-[50px]"}
              onChange={(e) => setFilterData((oldV) => ({ ...oldV, department: e.target.value }))}
            />
          </div>
        </div>

        <div className="flex flex-row gap-5">
          <div>
            <SearchBox className={"w-[300px] h-full"} placeholder="Search" onChange={(e) => onChangeTypeSearch(e)} />
          </div>
          <div className="my-auto button">
            <Button
              className={"w-[150px]"}
              onMouseEnter={() => {
                setEnter("white");
              }}
              onMouseLeave={() => {
                setEnter("#DD7224");
              }}
              onClick={clickExportLeaveExpired}
              label={
                <div className="flex px-4 gap-1">
                  <UserAddIcon color={isEnter} />
                  <div>Export</div>
                </div>
              }
            />
          </div>
        </div>
      </div>

      <div className="mt-5">
        <FetchingAndError isFetching={isFetchingLeaveExpired && isFetchingLeaveType} isError={errorLeaveExpired && errorLeaveType}>
          <LeaveExpiredDataTable
            dataTable={dataTable}
            currentPage={paginate.currentPage}
            totalData={paginate.totalData}
            rowsPerPage={paginate.rowsPerPage}
            onChangeCurrentPage={(val) => setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))}
            onChangeRowsPerPage={(val) => setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))}
            additionalLeaveTypeColumn={dataLeaveType ? dataLeaveType.data : []}
          />
        </FetchingAndError>
      </div>
    </div>
  );
};

export default LeaveExpired;
