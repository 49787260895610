import React from "react";
import {
  DataTable,
  ModalDialog,
  Pagination,
  TableMenu,
} from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import InformCircleSVG from "assets/icon/InformCircleSVG/InformCircleSVG";

export const LeaveBalanceDetailsDataTable = ({ leaveBalanceDetailsValue }) => {
  const cellDay = (value) => {
    return <React.Fragment>{value} Day</React.Fragment>;
  };
  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            id: "leaveType",
            accessorFn: (row) => <h1>{row.leaveTypeName}</h1>,
            enableSorting: true,
            header: () => <span>Leave Type</span>,
            cell: (status) => {
              return status.getValue();
            },
          },
          {
            id: "fullYearEntitlement",
            accessorFn: (row) => row.fullYearEntitlement,
            enableSorting: true,
            header: () => <span>Full Year Entitlement</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "ytdEarned",
            accessorFn: (row) => row.ytdEarned,
            enableSorting: true,
            header: () => <span>YTD Earned</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "broughtFoward",
            accessorFn: (row) => row.broughtForward,
            enableSorting: true,
            header: () => <span>Brought Foward</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "adjusment",
            accessorFn: (row) => row.adjustment,
            enableSorting: true,
            header: () => <span>Adjusment</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "ytdTaken",
            accessorFn: (row) => row.ytdTaken,
            enableSorting: true,
            header: () => <span>YTD Taken</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "bfForfeinted",
            accessorFn: (row) => row,
            enableSorting: true,
            header: () => <span>B/F Forfeinted</span>,
            cell: (status) => {
              return (
                <div className="flex flex-row gap-2 items-center">
                  <h1>{status.getValue().forfeitedBF} Day</h1>
                  <div
                    className="group flex relative"
                    onMouseEnter={() => {
                      return (
                        <div className="bg-red-500 fixed">
                          <ModalDialog />
                        </div>
                      );
                    }}
                  >
                    <InformCircleSVG color={"black"} />
                    <span class="group-hover:opacity-100 transition-opacity bg-white px-1 text-sm text-gray-100 rounded-md absolute left-1/2 -translate-x-1/2 opacity-0 m-4 mx-auto" style={{ zIndex: 1 }}>
                      <div class="rounded overflow-hidden shadow-lg">
                        <div class="px-6 py-4">
                          <div class="font-bold text-black text-xs whitespace-nowrap mb-2">Leave Expired</div>
                          <p class="font-bold text-orange-500 text-xs whitespace-nowrap">(Unused Carry Over will expired on 01/{`0${status.getValue().expiredCarryOver}`.slice(-2)}/{new Date().getFullYear()})</p>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              );
            },
          },
          {
            id: "pendingForApproval",
            accessorFn: (row) => row.pendingForApproval,
            enableSorting: true,
            header: () => <span>Pending For Approval</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "ytdBalance",
            accessorFn: (row) => row.ytdBalance,
            enableSorting: true,
            header: () => <span>YTD Balance</span>,
            cell: (status) => {
              return (
                <h1 className="font-semibold">{cellDay(status.getValue())}</h1>
              );
            },
          },
          {
            id: "projectedBalance",
            accessorFn: (row) => row.projectedBalance,
            enableSorting: true,
            header: () => <span>Projected Balance</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
        ]}
        data={leaveBalanceDetailsValue}
      />

      <div className="font-semibold text-sm mt-5">
        <h1>Notes:</h1>
        <h1>
          YTD Balance = (YTD Earned + Brought Forward + Adjustment) - (YTD Taken + B/F Forfeited + Pending Approval)
        </h1>
        <h1>
          Projected Balance = (Full Year Entitlement + Brought Forward + Adjustment) - (YTD Taken + B/F Forfeited + Pending Approval)
        </h1>
      </div>
    </React.Fragment>
  );
};

const LeaveBalanceDataTable = ({ dataTable, totalData, rowsPerPage, currentPage, onChangeCurrentPage, onChangeRowsPerPage, additionalLeaveTypeColumn }) => {
  const navigate = useNavigate();
  const cell = (status) => {
    return (
      <h1 className="cursor-pointer w-full">
        <span>
          {`${status.getValue()} Day`}
        </span>
      </h1>
    );
  };

  return (
    <React.Fragment>
      <DataTable
        title="Leave Data"
        columns={[
          {
            id: "employee",
            accessorFn: (row) => row,
            enableSorting: undefined,
            header: () => <span>Employee</span>,
            noPadding: true,
            cell: (status) => {
              const employee = status.getValue();
              return (
                <div className="flex flex-row gap-2 justify-center items-center h-[20px]">
                  <img
                    src={employee.photo}
                    alt="ProfileUser"
                    className="w-6 h-6 my-auto ml-4 rounded-full"
                  />
                  <span>{employee.employee}</span>
                </div>
              );
            },
          },
          {
            id: "uId",
            accessorFn: (row) => row.employeeID,
            header: () => <span>Employee ID</span>,
            enableSorting: true,
            cell: (status) => {
              return (
                <h1 className="cursor-pointer w-full">
                  {status.getValue()}
                </h1>
              );
            },
          },
          {
            id: "jobPosition",
            accessorFn: (row) => row.jobPosition,
            header: () => <span>Job Position</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              // const index = status.row.index;
              const department = status.getValue().department;
              return (
                <div className="w-full cursor-pointer h-[24px]">
                  <h1 className="">{status.getValue().position}</h1>
                  <h1 className="text-s text-gray-400">{department}</h1>
                </div>
              );
            },
          },
          ...additionalLeaveTypeColumn.map((itemType) => {
            return {
              id: itemType.name,
              accessorFn: (row) => row[itemType.name] !== undefined ? row[itemType.name].ytdBalance : null,
              header: () => <span>{itemType.name}</span>,
              enableSorting: true,
              cell: (status) => {
                return cell(status);
              },
            };
          }),
          /*
          {
            id: "annualLeave",
            accessorFn: (row) => `${row.annualLeave} Day`,
            header: () => <span>Annual Leave</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "sickLeave",
            accessorFn: (row) => `${row.sickLeave} Day`,
            header: () => <span>Sick Leave</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "offInLine",
            accessorFn: (row) => `${row.offInLine} Day`,
            header: () => <span>Off In Line</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "ns",
            accessorFn: (row) => `${row.ns} Day`,
            header: () => <span>NS</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          */
          {
            accessorFn: (row) => row.userID,
            disableSorting: true,
            header: () => {},
            id: "action",
            noPadding: true,
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit h-[20px]">
                  <TableMenu
                    show={true}
                    onDetail={() => { navigate(`/leave-balance-details/${status.getValue()}`) }}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export default LeaveBalanceDataTable;
