import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/leave-user`;

const leaveUserApi = createApi({
  reducerPath: "leaveUser",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
    fetchFn: async (...args) => {
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      fetchLeaveUserTypeByToken: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          result && result.data?.leaveTypeSettings?.length > 0 && tags.push(...result.data?.leaveTypeSettings.map((leaveType) => ({
            type: "LeaveUserType",
            id: leaveType._id,
          })));
          tags.push({ type: "LeaveUserTypeAll" });
          return tags;
        },
        query: (filter = {}) => {
          return {
            url: `/get-user-type-by-token`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchLeaveUserTypeByTokenQuery,
} = leaveUserApi;
export { leaveUserApi };
