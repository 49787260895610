import React from "react";
import PropTypes from "prop-types";

const ChevronDownSVG = ({ color, className, ...otherProps }) => {
  return (
    <svg
      className={`ml-[20px] ml-auto ${className}`}
      height="12"
      id="triangle-down"
      viewBox="0 0 32 32"
      width="15"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 8 H28 L16 26 z" />
    </svg>
  );
};

ChevronDownSVG.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

ChevronDownSVG.defaultProps = {
  color: "#111111",
  className: "",
};

export default ChevronDownSVG;
