import React from "react";

const InformCircleSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3465_103407)">
        <path
          d="M8.59999 14.6654C12.2819 14.6654 15.2667 11.6806 15.2667 7.9987C15.2667 4.3168 12.2819 1.33203 8.59999 1.33203C4.91809 1.33203 1.93332 4.3168 1.93332 7.9987C1.93332 11.6806 4.91809 14.6654 8.59999 14.6654Z"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.59998 10.6667V8"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.59998 5.33203H8.60664"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3465_103407">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.599976)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InformCircleSVG;
