import React, { useState } from "react";
import {
  Button,
  DottedButton,
  InputDate,
  InputSelect,
  InputText,
  ModalDialog,
  DataTable,
  TextArea,
  UploadImageBar,
} from "@bluesilodev/timhutcomponents";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from 'yup';
import SaveIconSVG from "assets/icon/SaveIconSVG/SaveIconSVG";
import TrashSVG from "assets/icon/TrashSVG/TrashSVG";
import XMarkSVG from "assets/icon/XMarkSVG/XMarkSVG";
import FetchingAndError from "components/fetchingAndError";
import { useAlertApi } from "services/alert-api";
import { useFetchLeaveBalanceUserQuery } from "store/apis/leaveBalanceApi";
import { useAddLeaveMutation } from "store/apis/leaveReportApi";
import { alertSuccess, alertError } from "utils/alert";

export const LeaveReportsRequestLeaveModalForm = ({
  dataTable,
  leaveReportRequestModal,
  leaveReportRequestModalOpen,
  deductFrom,
}) => {
  // Formik
  const formik = useFormik({
    initialValues: {
      leaveReportForm: {
        totalDuration: "",
        deductFrom: "",
        leaveReason: "",
        file: null,
      },
      leaveReportFormData: [{ date: "", duration: "fullTime", time: "" }],
      checkFormFile: { message: "", error: false },
    },
    validationSchema: Yup.object().shape({
      leaveReportForm: Yup.object().shape({
        totalDuration: Yup.string().required('Total Duration is required'),
        deductFrom: Yup.string().required('Deduct From is required'),
        leaveReason: Yup.string().required('Leave Reason is required'),
      }),
      leaveReportFormData: Yup.array().of(Yup.object().shape({
        date: Yup.string().required('Leave Date is required'),
        duration: Yup.string().required('Duration is required'),
      })),
    }),
    validateOnBlur: true,
    onSubmit: (values) => {
      if (values.checkFormFile.error) {
        return alertError("Error", "Please fill all date and blanks");
      }
      const getDateOnly = [];
      const leaveTimes = values.leaveReportFormData.map((val) => {
        if (val.date === "") {
          return undefined;
        }
        getDateOnly.push(val.date);
        const valueDate = val.date.split("/");
        const updateValueDate = [valueDate[1], valueDate[0], valueDate[2]].join("/");
        return { date: new Date(updateValueDate).toISOString(), duration: { durationType: val.duration, time: val.duration === "fullTime" ? 8 : 4, }, time: val.time };
      });
      if (leaveTimes.includes(undefined) || !values.leaveReportForm.leaveReason || !values.leaveReportForm.deductFrom) {
        return alertError("Error", "Please fill all date and blanks");
      }
      const duplicateDate = (new Set(getDateOnly)).size !== getDateOnly.length;
      if (duplicateDate) {
        return alertError("Error", "Duplicate date, please check again your leave date");
      }
      requestLeaveReports({ organizationID: undefined, userID: undefined, leaveReason: values.leaveReportForm.leaveReason, leaveTypeID: values.leaveReportForm.deductFrom, attachments: inputRef.current.files, leaveTimes: leaveTimes });
    },
  });
  // Data State
  const [{ photo, userName }, setEmployeeData] = useState({});
  const [leaveReportsDetailsBalance, setLeaveReportsDetailsBalance] = useState({ allData: [], filterData: [] });
  // Element State
  const inputRef = React.useRef(null);
  const [isEnter, setEnter] = useState("#DD7224");
  // Redux Toolkit
  const { data: dataLeaveBalance, error: errorLeaveBalance, isFetching: isFetchingLeaveBalance } = useFetchLeaveBalanceUserQuery({ id: null }, { refetchOnMountOrArgChange: true });
  const [requestLeaveReports, requestLeaveReportsResult] = useAddLeaveMutation();

  // Static State
  const durations = [{ label: "Full Shift (8 Hours)", value: "fullTime" }, { label: "Half Shift (4 Hours)", value: "halfTime" }];
  const time = [{ label: "08:00 - 12:00", value: "08:00 - 12:00" }, { label: "13:00 - 17:00", value: "13:00 - 17:00" }];

  React.useEffect(() => {
    if (dataLeaveBalance) {
      const year = new Date().getFullYear();
      const { balance: { [year]: balanceYear }, employeeInformation } = dataLeaveBalance;
      setEmployeeData({ photo: employeeInformation.photo?.[0]?.link || "", userName: employeeInformation.userName });
      setLeaveReportsDetailsBalance({ allData: balanceYear, filterData: balanceYear });
    }
  }, [dataLeaveBalance]);

  React.useEffect(() => {
    const totalDuration = formik.values.leaveReportFormData.reduce((acc, currentValue) => acc + (currentValue.duration === "fullTime" ? 8 : 4), 0);
    formik.setFieldValue("leaveReportForm.totalDuration", totalDuration);
  }, [formik.values.leaveReportFormData]);

  React.useEffect(() => {
    console.log("asd", leaveReportsDetailsBalance, formik.values.leaveReportForm.deductFrom);
    if (formik.values.leaveReportForm.deductFrom) {
      setLeaveReportsDetailsBalance((oldVal) => ({ ...oldVal, filterData: oldVal.allData.filter((sType) => sType.leaveTypeID === formik.values.leaveReportForm.deductFrom) }));
    } else {
      setLeaveReportsDetailsBalance((oldVal) => ({ ...oldVal, filterData: oldVal.allData }));
    }
  }, [formik.values.leaveReportForm.deductFrom]);

  const alertSuccessClose = (modal) => {
    leaveReportRequestModalOpen(false);
  };
  useAlertApi(requestLeaveReportsResult, alertSuccessClose);

  const checkFileLengthAndSize = (event, maxFile, maxSize) => {
    const allFiles = event.target.files;
    console.log("allfiles", allFiles);
    if (allFiles.length) {
      if (maxFile && allFiles.length > maxFile) {
        return formik.setFieldValue("checkFormFile", { message: "Maximum file limit", error: true });
      }
      if (maxSize && allFiles.filter((file) => file.size > maxSize).length > 0) {
        return formik.setFieldValue("checkFormFile", { message: "Maximum file size", error: true });
      }
      formik.setFieldValue("checkFormFile", { message: [...allFiles].map((file) => file.name).join(", "), error: false });
    } else {
      formik.setFieldValue("checkFormFile", { message: "", error: true });
    }
  };

  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };

  return (
    <React.Fragment>
      {leaveReportRequestModal && (
        <div className="w-full">
          <ModalDialog
            title={"Request Leave"}
            onClose={() => {
              leaveReportRequestModalOpen(false);
            }}
            className={"w-[1100px] p-6 max-h-[90%]"}
          >
            <FormikProvider value={formik}>
              <FieldArray
                name="leaveReportFormData"
                render={(arrayHelpers) => (
                  <React.Fragment>
                    {formik.values.leaveReportFormData.map((value, valIndex) => {
                      return (
                        <div className="w-full mt-5">
                          {valIndex === 0 && (<h1 className="font-semibold mb-2">Period of Employement</h1>)}
                          <div className="flex gap-3">
                            <div className="basis-1/3">
                              <InputDate label={"Date"} value={value.date} setFieldValue={(_, val) => { formik.setFieldValue(`leaveReportFormData[${valIndex}].date`, val) }} />
                            </div>
        
                            <div className="basis-1/3">
                              <InputSelect title={"Duration"} options={durations} classname={"w-full"} value={value.duration} onChange={(e) => formik.setFieldValue(`leaveReportFormData[${valIndex}].duration`, e.target.value)} />
                            </div>
        
                            {value.duration === "halfTime" && (
                              <div className="basis-1/3">
                                <InputSelect title={"Time"} options={time} classname={"w-full"} value={value.time} onChange={(e) => formik.setFieldValue(`leaveReportFormData[${valIndex}].time`, e.target.value)} />
                              </div>
                            )}
        
                            {valIndex === 0 && (<div className="flex bg-white w-[50px] h-[50px] mt-2"></div>)}
                            {valIndex !== 0 && (
                              <div className="flex rounded-full bg-white w-[50px] h-[50px] justify-center items-center mt-2 border-2  border-gray-200 border-solid" onClick={() => arrayHelpers.remove(valIndex)}>
                                <div>
                                  <TrashSVG color={"black"} />
                                </div>
                              </div>
                            )}
                            {value.duration === "fullTime" && (
                              <div className="basis-1/3">
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
        
                    <div className="w-full mt-5">
                      <div className="flex gap-3">
                        <DottedButton text="Add More Balance Generated" onClick={() => { arrayHelpers.push({ date: "", duration: "fullTime", time: "" }) }} />
                        <div className="flex bg-white w-[50px] h-[50px] mt-2"></div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              />

              <hr className="my-5" />

              <div className="w-full mt-5">
                <div className="flex gap-3">
                  <div className="basis-1/3">
                    <InputText title={"Total Duration"} label={<React.Fragment></React.Fragment>} placeholder="Total Duration" value={formik.values.leaveReportForm.totalDuration} onChange={(e) => formik.setFieldValue("leaveReportForm.totalDuration", e.target.value)} disabled={true} />
                  </div>

                  <div className="basis-1/3">
                    <InputSelect title={"Deduct From"} options={deductFrom} classname={"w-full"} onChange={(e) => formik.setFieldValue("leaveReportForm.deductFrom", e.target.value)} />
                  </div>

                  <div className="basis-1/3">
                  </div>

                  <div className="flex w-[50px] h-[50px]"></div>
                </div>
              </div>
              <div className="mt-5 w-full">
                <FetchingAndError isFetching={isFetchingLeaveBalance} isError={errorLeaveBalance}>
                  <DataTable
                    columns={[
                      {
                        id: "employee",
                        accessorFn: (row) => row.employee,
                        enableSorting: true,
                        header: () => <span className="m-2">Employee</span>,
                        cell: (status) => {
                          const employeeName = status.getValue();
                          return (
                            <div className="flex flex-row gap-2 justify-center items-center ">
                              <img
                                src={photo}
                                alt="ProfileUser"
                                className="w-6 h-6 my-auto ml-4 rounded-full"
                              />
                              <h1 className="m-2">{userName}</h1>
                            </div>
                          );
                        },
                      },
                      {
                        id: "leaveType",
                        accessorFn: (row) => row.leaveTypeName,
                        enableSorting: true,
                        header: () => <span className="m-2">Leave Type</span>,
                        cell: (status) => {
                          return cell(status);
                        },
                      },
                      {
                        id: "availableBalance",
                        accessorFn: (row) => `${row.ytdBalance} Day`,
                        enableSorting: true,
                        header: () => <span className="m-2">Available Balance</span>,
                        cell: (status) => {
                          return cell(status);
                        },
                      },
                      {
                        id: "deductFromLeave",
                        accessorFn: (row) => row.deductFromLeave,
                        enableSorting: true,
                        header: () => <span className="m-2">Deduct From Leave</span>,
                        cell: (status) => {
                          return (
                            <div className="w-full cursor-pointer">
                              <h1 className="">{formik.values.leaveReportForm.totalDuration / 8} Day</h1>
                            </div>
                          );
                        },
                      },
                      {
                        id: "remainingBalance",
                        accessorFn: (row) => row.ytdBalance,
                        enableSorting: true,
                        header: () => <span className="m-2">Remaining Balance</span>,
                        cell: (status) => {
                          return (
                            <h1 className="font-semibold m-2">
                              {status.getValue() - (formik.values.leaveReportForm.totalDuration / 8)} Day
                            </h1>
                          );
                        },
                      },
                    ]}
                    data={leaveReportsDetailsBalance.filterData}
                  />
                </FetchingAndError>
              </div>

              <div className="mt-5 w-full">
                <TextArea label={"Leave Reasons"} onChange={(e) => formik.setFieldValue("leaveReportForm.leaveReason", e.target.value)} />
              </div>

              <div className="mt-5 w-full">
                <UploadImageBar title="Attachments" required={false} type="file" inputRef={inputRef} message={formik.values.checkFormFile.message} accept="image/png, image/jpg, image/jpeg" multiple="multiple" onChange={(e) => checkFileLengthAndSize(e, 5)} />
              </div>

              <div className="flex justify-between mt-5 w-full gap-3">
                <Button
                  className={"w-full"}
                  onMouseEnter={() => {
                    setEnter("white");
                  }}
                  onMouseLeave={() => {
                    setEnter("#DD7224");
                  }}
                  label={
                    <div className="flex items-center px-4 gap-1">
                      <XMarkSVG color={isEnter} />
                      <div>Cancel</div>
                    </div>
                  }
                  onClick={() => {
                    leaveReportRequestModalOpen(false);
                  }}
                />
                <Button
                  className={"w-full"}
                  label={
                    <div className="flex items-center px-4 gap-1">
                      <SaveIconSVG color={"white"} />
                      <div>Save</div>
                    </div>
                  }
                  style={"solid"}
                  onClick={() => formik.submitForm()}
                />
              </div>
            </FormikProvider>
          </ModalDialog>
        </div>
      )}
    </React.Fragment>
  );
};
