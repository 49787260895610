import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/leave-setting`;

const leaveSettingsApi = createApi({
  reducerPath: "leaveSettings",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchLeaveSettings: builder.query({
        query: () => {
          return {
            url: "/get",
            method: "GET",
          };
        },
      }),
      addLeaveSettings: builder.mutation({
        query: (body) => {
          return {
            url: "/setting",
            method: "POST",
            body: {
              monthlyExecutiveLeaveLimit: body.monthlyExecutiveLeaveLimit,
            },
          };
        },
      }),
    };
  },
});

export const {
  useFetchLeaveSettingsQuery,
  useAddLeaveSettingsMutation,
} = leaveSettingsApi;
export { leaveSettingsApi };
